<template>
  <div class="auth-container">
    <div class="w-100 h-100">
      <div class="row m-2 h-100 justify-content-center">
        <div class="col-10 col-md-5 col-lg-3 align-self-center">
          <div class="w-100 d-flex justify-content-center">
            <img src="@/assets/images/logo.png" style="height: 60px" alt="" />
          </div>
          <div class="w-100 mt-3">
            <form id="auth-form" @submit.prevent="login">
              <div class="form-group mb-1">
                <input
                  type="text"
                  class="form-control"
                  v-model="models.identifier"
                  id="auth-email"
                  :class="{ 'border-danger': errors.identifier }"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <label class="form-control-label" for="email">Email</label>
              </div>
              <div class="form-group mb-2">
                <input
                  type="password"
                  class="form-control"
                  v-model="models.password"
                  :class="{ 'border-danger': errors.password }"
                  id="auth-password"
                  placeholder="Password"
                />
                <label class="form-control-label" for="email">Password</label>
              </div>
              <button type="submit" class="btn w-100">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="isProcessing"
                ></span>
                <span v-if="!isProcessing">Login</span>
              </button>
            </form>
            <button type="submit" class="btn btn-sm btn-secondary w-100 mt-2" @click="goTo('Signup')">
              <span>I don't have an account</span>
            </button>
            <!-- <div class="d-flex justify-content-end align-items-center p-2">
              <p class="m-0 mr-2 font-smaller">Are you new here?</p>
                <a @click="goTo('Signup')">Register Now</a>
            </div> -->
            <div
              class="w-100 d-flex justify-content-center text-white mt-3 cursor-pointer"
              @click="goTo('Forget')"
            >
              <p class="m-0 font-smaller">Forget password?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      models: {
        identifier: "",
        password: ""
      },
      errors: {
        identifier: false,
        password: false
      },
      isProcessing: false
    };
  },
  methods: {
    login() {
      console.log("login");
      this.isProcessing = true;
      const hasError = this.verifyForm();
      console.log(hasError);
      if (hasError) {
        console.log("has error");
        this.isProcessing = false;
      } else {
        console.log("proceed");
        API.post("auth/local", this.models)
          .then(retVal => {
            let user = retVal.data.user;
            console.log(user)

            delete user.interventions;

            if (user && user.confirmed) {
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem("token", retVal.data.jwt);

              // this.$router.push({
              //   name: "Projects"
              // });

              window.location.reload()
            } else {
              alert("Please wait for admin approval before you can login");
            }

            this.isProcessing = false;
          })
          .catch(err => {
            this.isProcessing = false;
            console.error(err);
            alert("Invalid username or password");
          });
      }
    },

    goTo(name) {
      console.log(name);
      this.$router.push({
        name: name
      });
    },

    verifyForm() {
      this.loginError = false;
      for (let index = 0; index < Object.keys(this.models).length; index++) {
        const key = Object.keys(this.models)[index];
        const model = this.models[key];

        if (!model) {
          this.errors[key] = true;
          this.loginError = true;
        } else {
          this.errors[key] = false;
        }
      }

      return this.loginError;
    }
  }
};
</script>
